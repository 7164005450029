:root {
  --main: #165b33;
  --main-light: #146b3a;
  --secondary: #bb2528;
  --secondary-light: #ea4630;
  --dark: #1a1a1a;
  --dark-semi-transparent: rgba(26, 26, 26, 0.5);
  --light: #f4f4f4;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--main);
  color: var(--light);
}

h1 {
  visibility: hidden;
  font-size: 0px;
  opacity: 0;
}

img {
  max-width: 259px !important;
  width: 100%;
  height: auto;
  margin: 30px auto;
  padding: 20px 0;
  user-select: none;
  pointer-events: none;
}

.title {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.container {
  max-width: 600px;
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  border-radius: 10px;
  justify-content: center;
  margin: 10px auto;
  background-color: var(--main-light);
  box-shadow: 0 0 10px 0 var(--dark-semi-transparent);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: var(--main);
  color: var(--light);
}

tr:nth-child(even) {
  background-color: var(--main);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.bounce1,
.bounce2,
.bounce3 {
  width: 20px;
  height: 20px;
  background-color: var(--secondary);
  border-radius: 100%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

.bounce1 {
  animation-delay: -0.32s;
}

.bounce2 {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

input,
select,
button {
  margin: 5px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--main);
  color: var(--light);
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
}

input[type='text'],
input[type='number'],
select {
  width: 100%;
}

input[type='text'],
input[type='number'] {
  background-color: var(--main);
}

button {
  background-color: var(--secondary);
  color: var(--light);
  cursor: pointer;
}

.total-price {
  text-align: center;
  margin-bottom: 10px;
}

.total-price,
table {
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}

.gap17 {
  gap: 17px !important;
}